import ArrowButton from "@/components/arrow-button/ArrowButton.vue";

export default {
  name: "CategoryCard",
  components: {
    ArrowButton,
  },
  data() {
    return {};
  },
  props: ["title", "slug", "image", "sectors", "id", "isScreen"],
  methods: {
    selectSector(sector) {
      this.$router.push({
        name: "SectorScreen",
        params: { category: this.slug, sector: sector.slug },
      });
    },
  },
};


