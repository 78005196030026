import { createApp } from "vue";
import App from "./components/app/App.vue";
import router from "./router";
import store from "./store";
import Vue3Sanitize from "vue-3-sanitize";
import "./index.css";

// Global components
import BaseDialog from "./UI/base-dialog/BaseDialog.vue";
import LoaderDots from "./layout/loader-dots/LoaderDots.vue";
import LoaderSearch from "./layout/loader-search/LoaderSearch.vue";
import BackButton from "./components/back-button/BackButton.vue";

const app = createApp(App);

app.use(router);
app.use(store);

const sanitizeConfig = {
  allowedTags: [
    "h1",
    "h2",
    "p",
    "br",
    "b",
    "strong",
    "i",
    "em",
    "u",
    "a",
    "ul",
    "li",
  ],
  allowedAttributes: {
    a: ["href", "target"],
  },
};

app.use(Vue3Sanitize, sanitizeConfig);

app.component("BaseDialog", BaseDialog);
app.component("LoaderDots", LoaderDots);
app.component("LoaderSearch", LoaderSearch);
app.component("BackButton", BackButton);

app.mount("#app");
