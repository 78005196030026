<template>
  <LoaderDots :isLoading="isLoading" />
  <BaseDialog :isOpen="isOpen" @close="close" @runAfterLeave="runAfterLeave" :isSolutionModal="true" >
    <template #default>
      <div v-if="isNavigating"
        class="absolute left-0 top-3 flex h-[2px] w-full items-center justify-center overflow-hidden px-4">
        <LoaderSearch :isLoading="isNavigating" />
      </div>
      <div
        class="solution-modal flex w-[95vw] h-[90vh] md:h-[80vh] md:w-[80vw] flex-col overflow-hidden pb-8"
        id="hotspot-body">
        <header
          class="sticky top-0 z-[999] flex items-center justify-between bg-[#F1F0FF] px-4 md:px-8 py-3 text-xl rounded-t-xl">
          <h1 class=" text-lg md:text-xl font-extrabold block md:hidden" v-html="hotspotTitle"></h1>
          <h1 class=" text-lg md:text-xl font-extrabold hidden md:block" v-html="hotspot.title"></h1>

          <div class="flex items-center justify-between text-xl md:justify-end py-0.5 md:gap-12 gap-4">
            <div class="flex gap-8">
              <button @click="prevSolution" class="hover:opacity-60">
                <img src="/images/icons/icon-arrow-simple-rg-blue.svg" alt="back" class="h-4 w-4 rotate-180" />
              </button>
              <button @click="nextSolution" class="hover:opacity-60">
                <img src="/images/icons/icon-arrow-simple-rg-blue.svg" alt="forward" class="h-4 w-4" />
              </button>
            </div>

            <button @click="close" class="font-bold">
              <XMarkIcon
                class="md:h-[28px] md:w-[28px] h-5 w-5 text-[#a7a7a7] transition-all duration-300 hover:text-[#0f0698]" />
            </button>
          </div>
        </header>

        <main class="flex w-full h-full flex-col md:flex-row">

          <aside v-if="hasImages" class="md:sticky md:top-3 h-[50vh] mx-5 md:ml-8 md:w-[43%] md:mt-5 relative z-50 top-4">
            <!-- Desktop / Tablet Gallery -->
            <ImageGallery class="hidden md:block z-[999]" :images="images" minimizedHeight="80%"
              minimizedWidth="calc(100%)" maximizedWidth="220%" maximizedHeight="68vh" />
            <!-- Mobile Gallery -->
            <ImageGallery class="md:hidden" :images="images" minimizedHeight="30vh" minimizedWidth="100%"
            :canFullScreen="false" />
          </aside>


          <section class="w-full md:w-[57%] h-[93%] px-6 solution overflow-y-auto">
            <div class="flex h-auto overflow-auto w-full flex-col gap-4 pb-10 pr-2 md:pr-8 md:mt-5 mt-10">
              <section>
                <h3 
                v-html="hotspot.headline"
                class="text-blue mb-7 md:max-w-[95%] text-xl md:text-2xl" 
                >
                </h3>
                <div v-html="hotspot.description"></div>
                <div class="w-full mb-8 mt-6 min-w-[130px] sm:border-1 h-auto sm:border-[#0f0698]" v-if="hotspot.more_info">
                  <MoreButton :url="hotspot.more_info" />

                </div>
              </section>

              <!-- Value Drivers Section -->
              <AccordionComponent title="value drivers" :isExpanded="true"
                 v-if="hotspot.drivers.length > 0">
                <section >
                  <ValueDriverButtons @openModal="openDriverModal" :filterDriverIds="hotspot.drivers"
                    :solution_modal="true" />
                </section>
              </AccordionComponent>

              <!-- Highlighted Solutions Section -->
              <AccordionComponent title="highlighted solutions" :isExpanded="true"
                v-if="highlightedSolutions.length > 0">
                <section >
                  <RouterLink v-for="(solution, index) in highlightedSolutions" :key="index" :to="solution.link">
                    <ArrowButton :title="solution.title" :dark_button="true" />
                  </RouterLink>
                </section>
              </AccordionComponent>

              <!-- Related Solutions Section -->
              <AccordionComponent title="related solutions" :isExpanded="true"
                v-if="relatedSolutions.length > 0">
                <section v-if="
                  relatedSolutions.length > 0
                  ">
                  <div class="flex w-full flex-wrap items-center gap-4">
                    <RouterLink v-for="(related, index) in relatedSolutions" :key="index" :to="related.link">
                      <SquareArrowButton :title="related.title" type="solution" />
                    </RouterLink>
                  </div>
                </section>
              </AccordionComponent>

              <!-- Related Content Section -->
              <AccordionComponent title="related content" :isExpanded="true"
                 v-if="relatedContent.length > 0">
                <section v-if="relatedContent.length > 0">
                  <div class="flex w-full flex-wrap items-center gap-4">
                    <a :href="related.url" target="_blank" v-for="(related, index) in relatedContent" :key="index">
                      <SquareArrowButton :title="related.title" type="content" :contentType="related.type" />
                    </a>
                  </div>
                </section>
              </AccordionComponent>

              <!-- Videos Section -->
              <AccordionComponent title="Videos" :isExpanded="true"
                 v-if="hotspot.videos.length > 0">
                <section v-if="hotspot.videos.length > 0
                  ">
                  <div class="mb-12 flex w-full flex-wrap items-center gap-4 pt-4"
                    v-for="(video, index) in hotspot.videos" :key="index">
                    <iframe class="aspect-video w-full" :src="video" allowfullscreen></iframe>
                  </div>
                </section>
              </AccordionComponent>
            </div>
          </section>
        </main>
      </div>
    </template>
  </BaseDialog>
  <ValueDriverModal v-model:isVisible="driverModalVisible" :valueDriverId="valueDriverId" :runAfterLeave="() => {
      this.isOpen = true
    }
    " />
</template>

<script src="./SolutionsHotspotModal"></script>
<style scoped lang="scss" src="./Style.scss"></style>
