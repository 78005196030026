// import createPersistedState from "vuex-persistedstate";
import { createStore } from "vuex";
import processesModule from "./modules/processes";
import portfoliosModule from "./modules/portfolios";
import languagesModule from "./modules/languages";
import valueDriversModule from "./modules/value-drivers";
import staticContentModule from "./modules/static-content";
import hotspotsModule from "./modules/hotspots";

const store = createStore({
  modules: {
    processes: processesModule,
    portfolios: portfoliosModule,
    languages: languagesModule,
    valueDrivers: valueDriversModule,
    hotspots: hotspotsModule,
    staticContent: staticContentModule,
  },
  state() {
    return {
      layers: [
        { id: "solutions", name: "solutions" },
        { id: "process", name: "process" },
        { id: "process-equipment", name: "process&nbsp;equipment" },
      ],
      activeLayer: { id: "process", name: "Process" },
    };
  },
  getters: {
    layers(state) {
      return state.layers;
    },
    activeLayer(state) {
      return state.activeLayer;
    },
  },
  mutations: {
    setActiveLayer(state, payload) {
      state.activeLayer = payload;
    },
  },
  actions: {
    setActiveLayer(context, payload) {
      context.commit("setActiveLayer", payload);
    },
  },
  // plugins: [createPersistedState()],
});

export default store;
