import { mapGetters } from "vuex";

export default {
  name: "BackInfoButtons",
  props: ["title"],
  methods: {
    openInfoModal: function () {
      this.$emit("help");
    },
    backToHome: function () {
      this.$router.push({
        name: "HomeScreen",
      });
    },
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
  },
};
