<template>
  <button
    @click="$router.back()"
    class="z-50 m-4 rounded-xl bg-white bg-opacity-70 p-2 py-2 px-4 text-lg shadow-lg backdrop-blur-sm backdrop-filter capitalize"
  >
    &lt; {{ getTranslations('back') }}
  </button>
</template>

<script lang="js" src="./BackButton.js"></script>
<style lang="scss" src="./BackButton.scss" scoped></style>
