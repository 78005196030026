import axios from "axios";
import { getApiBase } from "./host";
import store from '../store/index.js';

// const auth_token = process.env.AUTH_TOKEN;
const auth_token = 'VKa3MPCKvDL302BYwy03ebLJcF5FRaMsSVJwHFftTLc5';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth_token;

const getLanguageCode = () => {
  const active_language = store.getters['languages/activeLanguage'];
  return active_language ? active_language.two_digit_code : 'en';
};

const makeRequest = (url, resolve, reject) => {
  axios.get(url)
    .then(response => resolve(response.data))
    .catch(reject)
    .finally(() => {});
};

export const getImage = sectorId => {
  const url = `${getApiBase()}/v1/process/view?id=${sectorId}&lang=${getLanguageCode()}`;
  return new Promise((resolve) => makeRequest(url, response => resolve({ ...response, src: response.image }), console.log));
};

export const getPeg = slug => {
  const url = `${getApiBase()}/v1/hotspotprocess/sector-content/${slug}?scenario=peg&lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getSolutionHotspot = slug => {
  const url = `${getApiBase()}/v1/content/individual-content/${slug}?scenario=solution-modal&lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getProcessHotspot = slug => {
  const url = `${getApiBase()}/v1/content/individual-content/${slug}?scenario=process-modal&lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getLayers = () => {
  const url = `${getApiBase()}/v1/layer?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getNextContent = slug => {
  const url = `${getApiBase()}/v1/content/next-content/${slug}?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getPreviousContent = slug => {
  const url = `${getApiBase()}/v1/content/next-content/${slug}?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getStaticContent = () => {
  const url = `${getApiBase()}/v1/translation/static-content?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
}