<template>
  <div>
    <!-- top -->
    <div class="absolute z-[3001] top-2 left-28 flex flex-col items-center gap-1" 
    :class="{
      'hidden': !offscreenTop 
    }"
    >
      <img src="/images/icons/white-arrow.svg" alt="1" class="h-4 w-4" />
      <span
        class="flex h-8 w-8 items-center justify-center rounded-full border-[1.5px] border-white bg-[#0F0C88] p-2 text-lg font-black text-white"
        >
        {{
          offscreenTop
            }}
        </span
      >
    </div>
    <!-- left -->
    <div class="absolute z-[3001] top-[50%] left-2 flex flex-row items-center gap-1"     :class="{
      'hidden': !offscreenLeft || offscreenLeft == '1'
    }">
      <img
        src="/images/icons/white-arrow.svg"
        alt="1"
        class="h-4 w-4 -rotate-90"
      />
      <span
        class="flex h-8 w-8 items-center justify-center rounded-full border-[1.5px] border-white bg-[#0F0C88] p-2 text-lg font-black text-white"
        >
        {{
          offscreenLeft
            }}
        </span
      >
    </div>
    <!-- right -->
    <div
      class="absolute z-[3001] top-[50%] right-2 flex flex-row-reverse items-center gap-1"
          :class="{
      'hidden': !offscreenRight || offscreenRight == '1'
    }"
    >
      <img
        src="/images/icons/white-arrow.svg"
        alt="1"
        class="h-4 w-4 rotate-90"
      />
      <span
        class="flex h-8 w-8 items-center justify-center rounded-full border-[1.5px] border-white bg-[#0F0C88] p-2 text-lg font-black text-white"
        >    {{
          offscreenRight
            
            }}</span
      >
    </div>
    <!-- bottom -->
    <div
      class="absolute z-[3001] left-[40%] bottom-[10%] flex flex-col-reverse items-center gap-1"
          :class="{
      'hidden': !offscreenBottom || offscreenBottom == '1'
    }"
    >
      <img
        src="/images/icons/white-arrow.svg"
        alt="1"
        class="h-4 w-4 rotate-180"
      />
      <span
        class="flex h-8 w-8 items-center justify-center rounded-full border-[1.5px] border-white bg-[#0F0C88] p-2 text-lg font-black text-white"
        >
        {{
          offscreenBottom
            }}
        </span
      >
    </div>
  </div>

</template>

<script lang="js" src="./OffscreenHotspotsIndicator.js"></script>
<style lang="scss" src="./OffscreenHotspotsIndicator.scss" scoped></style>
