const SolutionsHotspotModal = () => import("../solutions-hotspot-modal/SolutionsHotspotModal.vue");
import InnovationSuperMarker from "./super-markers/innovation-super-marker/InnovationSuperMarker.vue"
import WaterSavingSuperMarker from "./super-markers/water-saving-super-marker/WaterSavingSuperMarker.vue";
import { mapGetters } from "vuex";

export default {
	name: "PegComponent",
	props: [
		"id",
		"layer",
		"portfolio",
		"drivers",
		"x",
		"y",
		"show",
		"slug",
		"title",
		"orderindex",
		"viewport_width",
		"viewport_height",
		"thumbnail",
		"percentage",
		"zoom",
	],
	components: {
		SolutionsHotspotModal,
		InnovationSuperMarker,
		WaterSavingSuperMarker,
	},
	data() {
		return {
			boundingClientRect: null,
			show_title: false,
			hovered: false,
			isGalleryFullScreen: false,
		};
	},
	computed: {
		...mapGetters(["activeLayer"]),
		...mapGetters("portfolios", ["activePortfolio"]),
		...mapGetters("valueDrivers", ["activeValueDrivers"]),

		isActiveLayer: function () {
			return this.layer === this.activeLayer.id;
		},
		isActivePortfolio: function () {
			if (this.activePortfolio == null || this.portfolio == 1) {
				return true;
			}
			if ((this.activePortfolio?.name ?? '').toLowerCase() === "all") {
				return true;
			}
			return this.portfolio == this.activePortfolio.id;
		},
		isActiveValueDriver: function () {
			if (this.activeValueDrivers == null) {
				return true;
			}

			if (this.activeValueDrivers.length === 0) {
				return true;
			}

			const driversSet = new Set(this.drivers);
			return this.activeValueDrivers.some((key_driver) =>
				driversSet.has(key_driver.id)
			);
		},
		isHotspotVisible: function () {
			if (this.activeLayer.id == "solutions") {
				return (
					this.isActiveLayer &&
					this.isActivePortfolio &&
					this.isActiveValueDriver
				);
			} else {
				return this.isActiveLayer;
			}
		},
	},
	methods: {
		getBoundingClientRect: function () {
			if (this.$refs.peg == null) {
				return new DOMRect();
			}

			if (this.boundingClientRect === null) {
				this.boundingClientRect = this.$refs.peg.getBoundingClientRect();
			}

			return this.boundingClientRect;
		},
		getAnimationDelay: function () {
			if (this.$refs.peg == null) {
				return "0ms";
			}

			const peg_rect = this.getBoundingClientRect();
			const delay = (peg_rect.left * 100) / this.viewport_width;

			if (this.show == false) {
				return delay / 2 + "ms";
			}

			return delay + "ms";
		},
		getActivePortfolioName: function () {
			return (this.activePortfolio?.name ?? '').toLowerCase()
		},
		calculatedX: function () {
			if (!this.show) {
				if (this.isLeftSide()) {
					return this.x - 0.008;
				}
				if (this.isRightSide()) {
					return this.x + 0.008;
				}
			}

			return this.x;
		},
		calculatedY: function () {
			if (!this.show) {
				if (this.isTopSide()) {
					return this.y - 0.004;
				}
				if (this.isBottomSide()) {
					return this.y + 0.004;
				}
			}
			return this.y;
		},
		isLeftSide: function () {
			const peg_rect = this.getBoundingClientRect();
			return peg_rect.left + peg_rect.width / 2 < this.viewport_width * 0.25;
		},
		isRightSide: function () {
			const peg_rect = this.getBoundingClientRect();
			return peg_rect.left + peg_rect.width / 2 > this.viewport_width * 0.75;
		},
		isTopSide: function () {
			const peg_rect = this.getBoundingClientRect();
			return peg_rect.top + peg_rect.height / 2 < this.viewport_height * 0.25;
		},
		isBottomSide: function () {
			const peg_rect = this.getBoundingClientRect();
			return peg_rect.top + peg_rect.height / 2 > this.viewport_height * 0.75;
		},
		openModal: function () {
			this.$router.push({
				name: "HotspotModal",
				params: { hotspotSlug: this.slug },
			});
		},
	},
};