import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "WaterSavingButton",
  data() {
    return {
      active: false,
    };
  },
  methods: {
    ...mapActions(["setActiveLayer"]),
    ...mapActions("portfolios", ["setActivePortfolio"]),
    ...mapActions("valueDrivers", ["setActiveValueDrivers"]),
    setLayerAndRedirect(layer) {
      this.$router.push({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: layer.id,
        },
      });
    },
    openWaterSaving(){
      this.setLayerAndRedirect({id: "solutions"});

      const water_saving = {id: 8, slug: 'water-saving', name: 'Water Saving'}
      const chemicals = {id: 2, slug: 'chemicals', name: 'Chemicals'}

      this.setActivePortfolio({ activePortfolio: water_saving });

      this.active = !this.active;

      if (!this.active) {
        this.setActivePortfolio({ activePortfolio: chemicals });
      }

      if ((this.activePortfolio?.name ?? '').toLowerCase() != 'water saving') {
        event.target.checked = false;
      }

      this.$emit('filterChange');
    },
    toggleActive(event) {
      if (this.active) {
        event.target.checked;
      }
    },
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
    ...mapState("portfolios", ["activePortfolio"]),
  },
  watch: {
    activePortfolio(new_val) {
      if ((new_val?.name ?? "").toLowerCase() !== "water saving") {
        this.active = false;
      }else{
        this.active = true;
      }
    },
  },
};
