import ProcessHotspotModal from "@/components/process-hotspot-modal/ProcessHotspotModal.vue";
import SolutionsHotspotModal from "@/components/solutions-hotspot-modal/SolutionsHotspotModal.vue";

export default {
  name: "HostspotModal",
  components: {
    ProcessHotspotModal,
    SolutionsHotspotModal,
  },
  props: [
    "layer",
  ],
  computed: {
    processHotspot() {
      return this.layer === "process" || this.layer === "process-equipment";
    },
    solutionsHotspot() {
      return this.layer === "solutions";
    },
  },
};
