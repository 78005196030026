import { getValueDrivers } from "../../../api/DiverseyRooms.API";
export default {
  namespaced: true,
  state() {
    return {
      valueDrivers: [],
      activeValueDrivers: [],
      apiStatus: "idle",
      errorMessage: null,
    };
  },
  getters: {
    valueDrivers(state) {
      return state.valueDrivers;
    },
    activeValueDrivers(state) {
      return state.activeValueDrivers;
    },
    apiStatus(state) {
      return state.apiStatus;
    },
  },
  mutations: {
    setValueDrivers(state, { res, err, apiStatus }) {
      state.valueDrivers = res;
      state.apiStatus = apiStatus;
      state.errorMessage = err;
    },
    setActiveValueDrivers(state, { valueDrivers }) {
      state.activeValueDrivers = valueDrivers;
    },
  },
  actions: {
    async setValueDrivers(context) {
      context.state.apiStatus = "busy";
      context.state.errorMessage = null;
      try {
        const res = await getValueDrivers();
        context.commit("setValueDrivers", {
          res,
          err: "null",
          apiStatus: "idle",
        });
        context.dispatch("setActiveValueDrivers", {
          activeValueDriver: res[0],
        });
      } catch (err) {
        console.error(err);
        context.commit("setValueDrivers", {
          res: "null",
          err: "An error has occured",
          apiStatus: "error",
        });
      }
    },
    setActiveValueDrivers(context, { valueDrivers }) {
      context.commit("setActiveValueDrivers", { valueDrivers });
    },
  },
};
