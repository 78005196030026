import { getStaticContent } from "../../../api/index.js";
export default {
  namespaced: true,
  state() {
    return {
      staticContent:{},
      apiStatus: "idle",
      errorMessage: null,
    };
  },
  getters: {
    staticContent(state) {
      return state.staticContent;
    },
    apiStatus(state) {
      return state.apiStatus;
    },
    getTranslations: (state) => (key) => {
      let translation = state.staticContent[key];

      if (!translation) {
        translation = key;
      }

      return translation;
    },
  },
  mutations: {
    setStaticContent(state, { res, err, apiStatus }) {
      state.staticContent = res;
      state.apiStatus = apiStatus;
      state.errorMessage = err;
    },
  },
  actions: {
    async setStaticContent(context) {
      context.state.apiStatus = "busy";
      context.state.errorMessage = null;
      try {
        const res = await getStaticContent();
        context.commit("setStaticContent", {
          res,
          err: null,
          apiStatus: "idle",
        });
      } catch (err) {
        console.error(err);
        context.commit("setStaticContent", {
          res: [],
          err: "An error has occured",
          apiStatus: "error",
        });
      }
    },
  },
};
