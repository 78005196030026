import PegComponent from "@/components/peg-component/PegComponent.vue";
import GridControls from "@/components/grid-controls/GridControls.vue";
import NoticeComponent from "@/components/notice-component/NoticeComponent.vue";
import InfoCard from "@/components/info-card/InfoCard.vue";
import FiltersMenu from "../../components/filters-menu/FiltersMenu.vue";
import SearchModal from "../../components/search-modal/SearchModal.vue";
import BackInfoButtons from "../../components/back-info-buttons/BackInfoButtons.vue";
import LanguageButton from "../../components/language-button/LanguageButton.vue";
import LanguageModal from "../../components/language-modal/LanguageModal.vue";
import ValueDriverButtons from "../../components/value-driver-buttons/ValueDriverButtons.vue";
import ValueDriverModal from "@/components/value-driver-modal/ValueDriverModal.vue";
import InnovationButton from "@/components/innovation-button/InnovationButton.vue";
import WaterSavingButton from "@/components/water-saving-button/WaterSavingButton.vue";
import OffscreenHotspotsIndicator from "@/components/offscreen-hotspots-indicator/OffscreenHotspotsIndicator.vue";
import { getPeg, getImage } from "../../api/index";
import { mapGetters, mapActions /* mapMutations*/ } from "vuex";

const MAX_ZOOM_TIMES = 5;
const TOTAL_ZOOM_STEPS = 4;
const ZOOM_ANIMATION_DURATION = 300;

export default {
  name: "GridScreen",
  components: {
    PegComponent,
    GridControls,
    NoticeComponent,
    InfoCard,
    FiltersMenu,
    SearchModal,
    BackInfoButtons,
    LanguageButton,
    LanguageModal,
    ValueDriverButtons,
    ValueDriverModal,
    InnovationButton,
    WaterSavingButton,
    OffscreenHotspotsIndicator,
  },
  data: function () {
    return {
      monitor_fps: 60,
      last_frame_time: performance.now(),
      inertia_timeout: null,
      is_dragging: false,
      last_drag_x_offset: 0,
      last_drag_y_offset: 0,
      drag_x_offset: 0,
      drag_y_offset: 0,
      original_image: null,
      original_image_width: 0,
      original_image_height: 0,
      source_image_width: 0,
      source_image_height: 0,
      original_image_aspect_ratio: 0,
      current_image_width: 0,
      current_image_height: 0,
      zoom_level: 1,
      min_zoom: 0,
      max_zoom: 1,
      zoom_step: 0.1,
      x_inertia: 0,
      y_inertia: 0,
      resize_timeout: null,
      loading: true,
      show_notice: false,
      has_placeholder: false,
      driverModalVisible: false,
      valueDriverId: "",
      offscreen_top: 0,
      offscreen_bottom: 0,
      offscreen_left: 0,
      offscreen_right: 0,
      show_search_modal: false,
      show_language_modal: false,
      visible_hotspots: [],
      hotspots: [],
      is_open: false,
    };
  },
  mounted() {
    console.log("mounted");
    requestAnimationFrame(this.calcualteMontorFps);
    this.inertia_timeout = setInterval(() => this.applyInertia(), 1000 / 60);
    document.addEventListener('modal-state-change', (event) => {
      this.is_open = event.detail.isOpen;
      this.handleModalStateChange(this.is_open);
    });


    this.handleModalStateChange(this.is_open);

    this.$store
      .dispatch("processes/updateCurrentSector", {
        category_slug: this.$route.params.category,
        sector_slug: this.$route.params.sector,
      })
      .then(() => {
        if (this.currentSector && this.currentSector.id) {
          let sector = this.currentSector.id;
          getImage(sector).then(this.onGetImage);
        } else {
          console.error("currentSector is null or undefined");
        }
      });
    this.$store.dispatch("staticContent/setStaticContent");
    this.setPortfolios();
    this.setLanguages();
    this.setValueDrivers();
    this.setActiveValueDrivers({ valueDrivers: [] });
  },
  beforeUnmount() {
    clearInterval(this.inertia_timeout);
    document.body.removeEventListener("mousedown", this.onMouseDown, true);
    document.body.removeEventListener("mouseup", this.onMouseUp, true);
    document.body.removeEventListener("mousemove", this.onMouseMove, true);
    document.body.removeEventListener("wheel", this.onMouseWheel, true);
    document.body.removeEventListener("touchend", this.onTouchEnd);
    document.body.removeEventListener("touchstart", this.onTouchStart);
    document.body.removeEventListener("touchmove", this.onTouchMove);
    window.removeEventListener("resize", this.onWindowResize);
  },
  beforeUpdate() {
    this.visible_hotspots = this.hotspots.filter(this.isHotspotVisible);
    if (this.visible_hotspots.length === 0) {
      return;
    }

    const center_x = window.innerWidth / 2 + Math.abs(this.drag_x_offset);
    const center_y = window.innerHeight / 2 + Math.abs(this.drag_y_offset);

    const x_threshold = window.innerWidth / 2.5;
    const y_threshold = window.innerHeight / 2.5;

    this.offscreen_top = 0;
    this.offscreen_bottom = 0;
    this.offscreen_left = 0;
    this.offscreen_right = 0;

    for (var i = 0; i < this.visible_hotspots.length; i++) {
      const hotspot = this.visible_hotspots[i];
      const hotspot_offset_x = hotspot.x * this.current_image_width;
      const hotspot_offset_y = hotspot.y * this.current_image_height;

      const x_distance = Math.abs(center_x - hotspot_offset_x);
      const y_distance = Math.abs(center_y - hotspot_offset_y);

      this.visible_hotspots[i].show =
        x_distance < x_threshold && y_distance < y_threshold;

      if (
        !hotspot.show &&
        hotspot.layer === this.activeLayer.id &&
        (this.activeLayer.id !== "solutions" ||
          (this.activePortfolio != null &&
            hotspot.portfolio === this.activePortfolio.id))
      ) {
        if (hotspot_offset_x < Math.abs(this.drag_x_offset) + x_threshold) {
          this.offscreen_left++;
        } else if (
          hotspot_offset_x >
          Math.abs(this.drag_x_offset) + (window.innerWidth - x_threshold)
        ) {
          this.offscreen_right++;
        } else if (
          hotspot_offset_y <
          Math.abs(this.drag_y_offset) + y_threshold
        ) {
          this.offscreen_top++;
        } else if (
          hotspot_offset_y >
          Math.abs(this.drag_y_offset) + (window.innerHeight - y_threshold)
        ) {
          this.offscreen_bottom++;
        }
      }
    }
  },
  methods: {
    ...mapActions("portfolios", ["setPortfolios", "setActivePortfolio"]),
    ...mapActions("languages", ["setLanguages", "setActiveLanguage"]),
    ...mapActions("valueDrivers", ["setValueDrivers", "setActiveValueDrivers"]),
    handleModalStateChange: function (is_open) {
      if (is_open) {
        document.body.removeEventListener("mousedown", this.onMouseDown, true);
        document.body.removeEventListener("mouseup", this.onMouseUp, true);
        document.body.removeEventListener("mousemove", this.onMouseMove, true);
        document.body.removeEventListener("wheel", this.onMouseWheel, true);
        document.body.removeEventListener("touchend", this.onTouchEnd, true);
        document.body.removeEventListener("touchstart", this.onTouchStart);
        document.body.removeEventListener("touchmove", this.onTouchMove);
        window.removeEventListener("resize", this.onWindowResize);
      } else {
        document.body.addEventListener("mousedown", this.onMouseDown, true);
        document.body.addEventListener("mouseup", this.onMouseUp, true);
        document.body.addEventListener("mousemove", this.onMouseMove, true);
        document.body.addEventListener("wheel", this.onMouseWheel, true);
        document.body.addEventListener("touchend", this.onTouchEnd, true);
        document.body.addEventListener("touchstart", this.onTouchStart);
        document.body.addEventListener("touchmove", this.onTouchMove);
        window.addEventListener("resize", this.onWindowResize);
      }
    },
    isActiveLayer: function (hotspot) {
      return hotspot.layer === this.activeLayer.id;
    },
    isActivePortfolio: function (hotspot) {
      if (this.activePortfolio == null || hotspot.portfolio == 1) {
        return true;
      }
      if ((this.activePortfolio?.name ?? "").toLowerCase() === "all") {
        return true;
      }
      return hotspot.portfolio == this.activePortfolio.id;
    },
    isActiveValueDriver: function (hotspot) {
      if (this.activeValueDrivers == null) {
        return true;
      }

      if (this.activeValueDrivers.length === 0) {
        return true;
      }

      const driversSet = new Set(hotspot.drivers);
      return this.activeValueDrivers.some((key_driver) =>
        driversSet.has(key_driver.id)
      );
    },
    isHotspotVisible: function (hotspot) {
      if (this.activeLayer.id == "solutions") {
        return (
          this.isActiveLayer(hotspot) &&
          this.isActivePortfolio(hotspot) &&
          this.isActiveValueDriver(hotspot)
        );
      } else {
        return this.isActiveLayer(hotspot);
      }
    },
    onFilterChange: function () {
      this.visible_hotspots = this.hotspots.filter(this.isHotspotVisible);
      this.updateImageSizeAndShape();
      this.$forceUpdate();
    },
    calcualteMontorFps: function () {
      this.monitor_fps = Math.round(
        1000 / (performance.now() - this.last_frame_time)
      );
      this.last_frame_time = performance.now();
      requestAnimationFrame(this.calcualteMontorFps);
    },
    onMouseDown: function (e) {
      this.is_dragging = true;
      this.last_drag_x_offset = e.clientX;
      this.last_drag_y_offset = e.clientY;
    },
    onMouseUp: function () {
      this.is_dragging = false;
    },
    onMouseMove: function (e) {
      if (!this.is_dragging) {
        return;
      }

      this.drag_x_offset += e.clientX - this.last_drag_x_offset;
      this.drag_y_offset += e.clientY - this.last_drag_y_offset;
      this.x_inertia = e.clientX - this.last_drag_x_offset;
      this.y_inertia = e.clientY - this.last_drag_y_offset;
      this.last_drag_x_offset = e.clientX;
      this.last_drag_y_offset = e.clientY;

      this.updateImageSizeAndShape();
    },
    onTouchEnd() {
      console.log("touch end");
      this.is_dragging = false;
    },
    onTouchStart(event) {
      console.log("touch start");

      const touch = event.touches[0] || event.changedTouches[0];
      this.last_client_x = touch.clientX;
      this.last_client_y = touch.clientY;
      this.is_dragging = true;
    },
    onTouchMove(event) {
      const touch = event.touches[0] || event.changedTouches[0];
      this.drag_x_offset += touch.clientX - this.last_drag_x_offset;
      this.drag_y_offset += touch.clientY - this.last_drag_y_offset;
      this.x_inertia = touch.clientX - this.last_drag_x_offset;
      this.y_inertia = touch.clientY - this.last_drag_y_offset;
      this.last_drag_x_offset = touch.clientX;
      this.last_drag_y_offset = touch.clientY;

      this.updateImageSizeAndShape();
    },
    onMouseWheel: function (e) {
      const delta = Math.sign(e.deltaY) / 30;
      const amount = delta * this.zoom_step;

      this.applyZoomChange(amount);
    },
    updateZoom: function (amount) {
      const zoom_frames = this.remap(
        ZOOM_ANIMATION_DURATION,
        1,
        1000,
        1,
        this.monitor_fps
      );
      const step_amount = amount / zoom_frames;

      for (var i = 0; i < zoom_frames; i++) {
        setTimeout(
          () => this.applyZoomChange(step_amount),
          (ZOOM_ANIMATION_DURATION / zoom_frames) * i
        );
      }
    },
    applyZoomChange: function (amount) {
      const last_width = this.current_image_width;
      const last_height = this.current_image_height;
      this.zoom_level = Math.min(
        this.max_zoom,
        Math.max(this.min_zoom, this.zoom_level - amount)
      );
      this.updateImageSizeAndShape();

      const viewport_width = window.innerWidth;
      const center_of_viewport_on_image_x =
        Math.abs(this.drag_x_offset) + viewport_width / 2;
      const x_percentage =
        center_of_viewport_on_image_x / this.current_image_width;
      const changed_size_x = this.current_image_width - last_width;
      const x_addition = changed_size_x * x_percentage;
      this.drag_x_offset -= x_addition;

      const viewport_height = window.innerHeight;
      const center_of_viewport_on_image_y =
        Math.abs(this.drag_y_offset) + viewport_height / 2;
      const y_percentage =
        center_of_viewport_on_image_y / this.current_image_height;
      const changed_size_y = this.current_image_height - last_height;
      const y_addition = changed_size_y * y_percentage;
      this.drag_y_offset -= y_addition;
      this.updateImageSizeAndShape();
    },
    applyInertia: function () {
      if (!this.is_dragging) {
        var something_changed = false;
        if (Math.abs(this.x_inertia) > 0.1) {
          this.x_inertia = this.x_inertia * 0.9;
          this.drag_x_offset += this.x_inertia;
          something_changed = true;
        } else {
          this.x_inertia = 0;
        }

        if (Math.abs(this.y_inertia) > 0.1) {
          this.y_inertia = this.y_inertia * 0.9;
          this.drag_y_offset += this.y_inertia;
          something_changed = true;
        } else {
          this.y_inertia = 0;
        }

        if (something_changed) {
          this.updateImageSizeAndShape();
        }
      }
    },
    updateImageSizeAndShape: function () {
      this.current_image_width = this.original_image_width * this.zoom_level;
      this.current_image_height =
        this.current_image_width / this.original_image_aspect_ratio;

      this.drag_x_offset = Math.min(this.drag_x_offset, 0);
      this.drag_x_offset = Math.max(
        this.drag_x_offset,
        window.innerWidth - this.current_image_width
      );
      this.drag_y_offset = Math.min(this.drag_y_offset, 0);
      this.drag_y_offset = Math.max(
        this.drag_y_offset,
        window.innerHeight - this.current_image_height
      );
    },
    getImageStyle: function () {
      const style = {
        transform: `translate(${this.drag_x_offset}px, ${this.drag_y_offset}px)`,
        width: `${this.current_image_width}px`,
        height: `${this.current_image_height}px`,
        aspectRatio: `${this.original_image_aspect_ratio}`,
      };

      if (this.original_image != null) {
        style.backgroundImage = `url(${this.original_image})`;
      } else {
        style.backgroundColor = "black";
      }

      return style;
    },
    onGetImage: function (image) {
      if (image.src == null) {
        this.original_image = "/images/layout/placeholder-bg.png";
        this.original_image_width = window.innerWidth;
        this.original_image_width = window.innerHeight;
        this.source_image_width = window.innerWidth;
        this.source_image_height = window.innerHeight;
        this.original_image_aspect_ratio = image.width / image.height;
        this.has_placeholder = true;
        this.updateZoomConfig(false);
        this.updateImageSizeAndShape();
        return;
      }

      this.original_image = image.src;
      this.original_image_width = image.width;
      this.original_image_height = image.height;
      this.source_image_width = image.width;
      this.source_image_height = image.height;
      this.original_image_aspect_ratio = image.width / image.height;
      this.updateZoomConfig(false);
      this.updateImageSizeAndShape();

      getPeg(this.$route.params.sector).then(this.onGetHotspots);
    },
    onWindowResize: function () {
      clearTimeout(this.resize_timeout);
      this.resize_timeout = setTimeout(() => {
        this.updateZoomConfig(true);
        this.updateImageSizeAndShape();
      }, 100);
    },
    updateZoomConfig: function () {
      const screen_width = window.innerWidth;
      const screen_height = window.innerHeight;
      if (this.source_image_width > screen_width) {
        this.current_image_width = screen_width;
        this.current_image_height =
          screen_width / this.original_image_aspect_ratio;
      } else {
        this.current_image_width = screen_width;
        this.current_image_height =
          screen_width / this.original_image_aspect_ratio;
      }
      if (this.current_image_height < screen_height) {
        this.current_image_height = screen_height;
        this.current_image_width =
          screen_height * this.original_image_aspect_ratio;
      }
      this.zoom_level = this.current_image_width / this.original_image_width;
      this.min_zoom = this.zoom_level;
      this.max_zoom = this.zoom_level * MAX_ZOOM_TIMES;
      this.zoom_step = (this.max_zoom - this.min_zoom) / TOTAL_ZOOM_STEPS;
      this.$forceUpdate();
    },
    onGetHotspots: function (hotspots) {
      hotspots = hotspots.map((hotspot) => {
        hotspot.show = true;
        return hotspot;
      });
      this.hotspots = hotspots;
      this.$store.commit("hotspots/setHotspots", hotspots);
      this.loading = false;
    },
    showSearchModal: function () {},
    toggleLanguageModal: function () {
      this.show_language_modal = !this.show_language_modal;
    },
    updateLanguages: function (language) {
      this.languages = this.languages.map((lang) => {
        if (lang === language) {
          lang.selected = true;
          return lang;
        } else {
          lang.selected = false;
          return lang;
        }
      });
    },
    remap(number, inMin, inMax, outMin, outMax) {
      return ((number - inMin) * (outMax - outMin)) / (inMax - inMin) + outMin;
    },
    showNotice: function () {
      const hasShownNotice = localStorage.getItem("hasShownNotice") === "true";
      if (!hasShownNotice) {
        this.show_notice = true;
        localStorage.setItem("hasShownNotice", "true");
      }
    },
    help: function () {
      this.show_notice = true;
    },
    openDriverModal(valueDriverId) {
      this.isOpen = false;
      this.driverModalVisible = true;
      this.valueDriverId = valueDriverId;
    },
  },
  computed: {
    ...mapGetters("processes", ["currentCategory", "currentSector"]),
    ...mapGetters(["activeLayer"]),
    ...mapGetters("portfolios", ["activePortfolio"]),
    ...mapGetters("languages", ["activeLanguage"]),
    ...mapGetters("valueDrivers", ["activeValueDrivers"]),
    ...mapGetters("staticContent", ["getTranslations"]),
    roomTitle() {
      return this.currentSector?.title;
    },
  },
  watch: {
    show_language_modal(newVal) {
      const event = new CustomEvent('modal-state-change', {
        detail: { isOpen: newVal }
      });
      document.dispatchEvent(event);
    }
  },
  created() {
    this.hasShownNotice = localStorage.getItem("hasShownNotice") === "true";
    if (!this.hasShownNotice) {
      this.show_notice = true;
      localStorage.setItem("hasShownNotice", "true");
    }
  },
};
