export const getApiBase = function () {
	// const origin = window.location.origin;

	// if (origin.includes('staging.diverseygps.com')) {
	// 	return 'https://api.staging.diverseygps.com';
	// }

	// if (origin.includes('diverseygps.com')) {
		return 'https://api.diverseygps.com';
	// }

	// return 'http://api.diversey-gps.local';
}
