import { getPortfolios } from "../../../api/DiverseyRooms.API";
export default {
  namespaced: true,
  state() {
    return {
      portfolios: [],
      activePortfolio: null,
      apiStatus: "idle",
      errorMessage: null,
    };
  },
  getters: {
    portfolios(state) {
      return state.portfolios;
    },
    activePortfolio(state) {
      return state.activePortfolio;
    },
    apiStatus(state) {
      return state.apiStatus;
    },
  },
  mutations: {
    setPortfolios(state, { res, apiStatus }) {
      state.portfolios = res;
      state.apiStatus = apiStatus;
    },

    setActivePortfolio(state, { activePortfolio }) {
      state.activePortfolio = activePortfolio;
    },
  },
  actions: {
    async setPortfolios(context) {
      context.state.apiStatus = "busy";
      context.state.errorMessage = null;
      try {
        const res = await getPortfolios();
        context.commit("setPortfolios", {
          res,
          apiStatus: "idle",
        });
        context.dispatch("setActivePortfolio", {
          activePortfolio: res[1],
        });
      } catch (err) {
        console.error(err);
        context.commit("setPortfolios", {
          res: "null",
          apiStatus: "error",
        });
      }
    },
    setActivePortfolio(context, { activePortfolio }) {
      context.commit("setActivePortfolio", { activePortfolio });
    },
  },
};
