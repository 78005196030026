import { createRouter, createWebHistory } from 'vue-router';
import HomeScreen from '../screens/home-screen/HomeScreen.vue';
import GridScreen from '../screens/grid-screen/GridScreen.vue';
import CategoryScreen from '../screens/category-screen/CategoryScreen.vue';
import HotspotModal from '../components/hotspot-modal/HotspotModal.vue';
import store from '../store/index.js';
import { setActiveLanguage } from './utils';

const routes = [
  {
    path: '/',
    redirect: () => {
      const activeLanguage = store.getters['languages/activeLanguage'];
      return activeLanguage ? `/${activeLanguage.two_digit_code}` : '/default-language';
    },
  },
  { path: '/:lang', component: HomeScreen, name: 'HomeScreen' },
  { path: '/:lang/:category', component: CategoryScreen, name: 'CategoryScreen' },
  {
    path: '/:lang/:category/:sector',
    component: GridScreen,
    name: 'SectorScreen',
    redirect: (to) => ({
      name: 'LayerScreen',
      params: {
        lang: to.params.lang,
        category: to.params.category,
        sector: to.params.sector,
        layer: 'process',
      },
    }),
  },
  {
    path: '/:lang/:category/:sector/:layer',
    component: GridScreen,
    name: 'LayerScreen',
    children: [
      {
        path: ':hotspotSlug',
        component: HotspotModal,
        name: 'HotspotModal',
        props: true,
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach(async (to, from, next) => {
  await setActiveLanguage(store);

  const activeLanguage = store.getters['languages/activeLanguage'];

  if (to.path === '/') {
    if (activeLanguage) {
      return next({ path: `/${activeLanguage.two_digit_code}` });
    }
    return next();
  }

  if (window.location.hostname === 'diverseygps.com') {
    window.location.href = `https://www.diverseygps.com${to.fullPath}`;
    return;
  }

  if (to.name === 'LayerScreen') {
    const layers = store.getters.layers;
    const layer = layers.find((l) => l.id === to.params.layer);
    store.dispatch('setActiveLayer', layer);
  }

  if (!to.params.lang && activeLanguage) {
    return next({ ...to, params: { ...to.params, lang: activeLanguage.two_digit_code } });
  }

  if (to.params.lang && activeLanguage && to.params.lang !== activeLanguage.two_digit_code) {
    return next({ ...to, params: { ...to.params, lang: activeLanguage.two_digit_code } });
  }

  return next();
});

export default router;
