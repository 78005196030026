import { mapGetters } from "vuex";

export default {
  name: "LanguageButton",
  props: ["language", "toggleLanguageModal"],
  computed: {
    ...mapGetters("languages", ["languages", "activeLanguage"]),
  },
  methods: {
    
  }
};
