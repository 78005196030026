import { MinusIcon, PlusIcon } from "@heroicons/vue/24/outline";

export default {
  name: "GridControls",
  props: ["zoom", "can_zoom_in", "can_zoom_out"],
  components: {
    MinusIcon,
    PlusIcon,
  },
  methods: {
    onZoomIn: function () {
      if (!this.can_zoom_in) {
        return;
      }

      this.$emit("zoom-in");
    },
    onZoomOut: function () {
      if (!this.can_zoom_out) {
        return;
      }

      this.$emit("zoom-out");
    },
    openSearchModal: function () {
      this.$emit("open-search-modal");
      this.$nextTick(() => {
        const searchInput = document.querySelector('#search-input');
        if (searchInput) {
          searchInput.focus();
        }
      });
    },
  },
};
