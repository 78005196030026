export default {
  name: "OffscreenIndicator",
  props: {
    offscreenTop: {
      type: Number,
      required: true,
    },
    offscreenBottom: {
      type: Number,
      required: true,
    },
    offscreenLeft: {
      type: Number,
      required: true,
    },
    offscreenRight: {
      type: Number,
      required: true,
    },
  },
  data: () => ({
  }),
  methods: {},
};