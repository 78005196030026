<template>
  <div
    ref="infoCard"
    @click="toggleInfo"
    class="hidden lg:block fixed bottom-0 left-1/2 z-[1000] m-4 max-w-[500px] w-full -translate-x-1/2 cursor-pointer gap-4 rounded-xl bg-white bg-opacity-70 py-2 px-4 shadow-lg backdrop-blur-sm backdrop-filter z-[9999999999999999]"
  >
    <header
      class="flex justify-between text-lg transition-all duration-300"
      :class="{ 'mb-4': expanded }"
    >
      <h1 class="text-sm">{{ title }}</h1>
      <img v-if="!expanded" width="18" src="/images/icons/info-icon.svg" alt="Info icon" />
      <button v-if="expanded" class="font-bold">
        <XMarkIcon
          class="md:h-[28px] md:w-[28px] h-5 w-5 text-[#a7a7a7] transition-all duration-300 hover:text-[#0f0698]" />
      </button>
    </header>
    <section
      class="flex flex-col gap-4 overflow-hidden text-black transition-all duration-500 ease-in-out"
      :class="expanded ? 'h-[250px]' : 'h-0'">
      <div class="text-sm h-full overflow-scroll">
        {{ content }}
      </div>
    </section>
  </div>
</template>
<script lang="js" src="./InfoCard"></script>
