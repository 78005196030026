<template>
  <div
    v-if="isOpen"
    @click="$emit('close')"
    class="fixed top-0 left-0 z-[3001] h-screen w-full bg-black bg-opacity-50"
  ></div>
  <transition name="modal" @after-leave="$emit('runAfterLeave')">
    <dialog
      open
      v-if="isOpen"
      :class="{
        'top-0 bottom-0 p-0 bg-white': !isSearch,
        'bottom-none top-32 p-0 bg-white': isSearch,
        'p-0': isSolutionModal,
      }"
      class="z-[9999999999] rounded-xl  shadow-lg bg-black"
    >
      <slot> </slot>
    </dialog>
  </transition>
</template>

<script lang="js" src="./BaseDialog"></script>
<style lang="scss" src="./BaseDialog.scss" scoped></style>
