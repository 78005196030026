import CategoryCard from "@/components/category-card/CategoryCard.vue";
import { mapGetters } from "vuex";

export default {
  name: "CategoryScreen",
  components: {
    CategoryCard,
  },
  computed: {
    ...mapGetters("processes", [
      "currentCategory",
      "apiStatus",
      "errorMessage",
    ]),
    isLoading() {
      return this.apiStatus === "busy";
    },
    isIdle() {
      return this.apiStatus === "idle";
    },
  },
  created() {
    this.$store.dispatch("processes/updateCurrentCategory", {
      category_slug: this.$route.params.category,
    });
  },
};
