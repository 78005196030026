<template>
  <form @submit.prevent="" class="px-4 py-2">
    <div
      class="flex h-12 gap-5 text-[#0f0f98]"
    >
      <input
      id="search-input"
        class="w-full bg-transparent text-lg placeholder:text-gray-600 border-0 focus:border-0 border-transparent focus:border-transparent focus:ring-0"
        :placeholder="decodeHtml(placeholder)"
        type="text"
        @input="onInput"
      />
      <button @click="close" class="font-bold">
        <XMarkIcon
          class="md:h-[28px] md:w-[28px] h-5 w-5 text-[#a7a7a7] transition-all duration-300 hover:text-[#0f0698]" />
      </button>
    </div>
  </form>
</template>

<script src="./SearchBar.js" lang="js"></script>