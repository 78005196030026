import {
  ArrowsPointingInIcon,
  ArrowsPointingOutIcon,
  ChevronRightIcon,
  ChevronLeftIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "ImageGallery",
  components: {
    ArrowsPointingInIcon,
    ArrowsPointingOutIcon,
    ChevronRightIcon,
    ChevronLeftIcon,
  },
  props: {
    images: Array,
    minimizedHeight: String,
    minimizedWidth: String,
    maximizedHeight: String,
    maximizedWidth: String,
    canFullScreen: {
      type: Boolean,
      default: true,
      required: false,
    }
  },
  data() {
    return {
      isGalleryFullScreen: false,
      currentSlide: 0,
    };
  },
  computed: {
    maxSlides() {
      return this.images.length - 1;
    },
  },
  watch: {
    images() {
      this.currentSlide = 0;
    },
  },
  methods: {
    toggleFullScreenGallery() {
      this.isGalleryFullScreen = !this.isGalleryFullScreen;
		let body = document.querySelector("#hotspot-body");	
    let solution = document.querySelector(".solution");
		if (this.isGalleryFullScreen) {
			body.scrollTop = 0;
			body.classList.remove("overflow-scroll");
			body.classList.add("overflow-hidden");
      solution.classList.add("hidden");

			return;
		}
    solution.classList.remove("hidden");
		body.classList.remove("overflow-hidden");
		body.classList.add("overflow-scroll");
    },
    nextSlide() {
      if (this.currentSlide === this.maxSlides) {
        this.currentSlide = 0;
        return;
      }
      this.currentSlide++;
    },
    prevSlide() {
      if (this.currentSlide === 0) {
        this.currentSlide = this.maxSlides;
        return;
      }
      this.currentSlide--;
    },
    setActiveSlide(index) {
      this.currentSlide = index;
    },
  },
};
