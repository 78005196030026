import {
  XMarkIcon,
} from "@heroicons/vue/24/outline";

export default {
  name: "InfoCard",
  components: {
    XMarkIcon,
  },
  props: {
    title: String,
    content: String,
  },
  data() {
    return {
      expanded: false,
    };
  },
  methods: {
    toggleInfo() {
      this.expanded = !this.expanded;
    },
    handleClickOutside(event) {
      if (this.expanded && !this.$refs.infoCard.contains(event.target)) {
        this.expanded = false;
      }
    },
  },
  mounted() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeUnmount() {
    document.removeEventListener("click", this.handleClickOutside);
  },
};