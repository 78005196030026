<template>
  <div
    @click="click_handler"
    class="flex w-full cursor-pointer items-center justify-between gap-1 bg-white px-4 py-4"
    :class="{
      'bg-[#f3f5f9] transition-all hover:bg-[#d5d5d5]': dark_button,
      'rounded-md bg-opacity-70 shadow-lg backdrop-blur-sm backdrop-filter':
        !dark_button,
      'my-0': no_margin,
      'my-2': !no_margin,
    }"
  >
    <p
      v-html="title"
      class="text-left text-lg font-medium text-dark-blue"
      :class="{
        'font-bold text-black': dark_button,
      }"
    ></p>
    <img
      :src="
        dark_button
          ? '/images/icons/icon-arrow-rg-blue.png'
          : '/images/icons/green-arrow-icon.svg'
      "
      alt="arrow icon"
      class="z-[1] w-4"
    />
  </div>
</template>

<script lang="js" src="./ArrowButton.js"></script>
<style lang="scss" src="./ArrowButton.scss" scoped></style>
