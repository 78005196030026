<template>
  <section class="h-screen w-screen">
    <BackButton class="fixed top-0 left-0" />
    <div
      v-if="isLoading"
      class="flex h-full w-full items-center justify-center"
    >
      <LoaderDots :isLoading="isLoading" />
    </div>
    <CategoryCard v-if="isIdle" v-bind="currentCategory" :isScreen="true" />
  </section>
</template>

<script lang="js" src="./CategoryScreen"></script>
