<template>
  <button @click="openWaterSaving" type="button"
    class="water-saving-btn text-semibold flex hidden items-center rounded-[28px] pl-4 pr-2.5 py-1.5 text-center text-sm text-black shadow-lg lg:flex justify-between">
    <div id="water-saving-icon" class="flex items-center justify-between">
      <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M2.5 10C2.66667 10.5 3.5 11.7 5.5 12.5" />
        <path
          d="M10.5 9.5C10.9 6.7 7.33333 2.66667 5.5 1C4 2.5 1 6.3 1 9.5C1 10.9798 1.64155 12.0177 2.47964 12.7223C4.16388 14.1385 6.81952 14.2078 8.62381 12.9482C9.49834 12.3377 10.2411 11.3123 10.5 9.5Z" />
      </svg>
      <span class="btn-label text-center capitalize pl-2 pr-5" v-html="getTranslations('water saving')">
      </span>
    </div>
    <label class="switch relative inline-block" for="water-saving-toggle">
      <input id="water-saving-toggle" class="w-full h-full border-none opacity-0 absolute top-0 left-0 cursor-pointer"
        type="checkbox" :checked="active" @change="toggleActive">
      <span class="slider pointer-events-none"></span>
    </label>
  </button>
</template>

<script lang="js" src="./WaterSavingButton.js"></script>
<style lang="scss" src="./WaterSavingButton.scss" scoped></style>
