import { XMarkIcon } from "@heroicons/vue/24/outline";
import { mapGetters } from "vuex";

export default {
  name: "NoticeComponent",
  props: ["isVisible", "title", "roomDescription"],
  components: {
    XMarkIcon,
  },
  data() {
    return {
      currentTutorial: 0,
      tutorials: [
        {
          id: 1,
          title:
            "click and drag to move around. click '+' or '-' to zoom in and out.",
          image: "/images/tutorial/t1.png",
        },
        {
          id: 2,
          title: "click on the 'hotspots' to explore the factory.",
          image: "/images/tutorial/t2.png",
        },
        {
          id: 3,
          title: "filter hotspots by clicking on the layers menu.",
          image: "/images/tutorial/t3.png",
        },
        {
          id: 4,
          title:
            "explore specifics with the 'Solution Portfolio' and 'Key Drivers'.",
          image: "/images/tutorial/t4.png",
        },
      ],
    };
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
    lastTutorial() {
      return this.currentTutorial === this.tutorials.length - 1;
    },
    translatedTutorials() {
      return this.tutorials.map(tutorial => ({
        ...tutorial,
        title: this.getTranslations(tutorial.title),
      }));
    },
    roomTitle() {
      let placeholder = this.getTranslations("wording");
      let welcome_message = this.getTranslations("welcome to wording");
      let translated_message = welcome_message.replace(placeholder, this.title)
      return translated_message;
    }
  },
  methods: {
    close() {
      this.$emit("update:isVisible", false);
    },
    nextTutorial() {
      if (this.lastTutorial) {
        this.close();
        return;
      }
      this.currentTutorial++;
    },
    prevTutorial() {
      if (this.currentTutorial === 0) {
        return;
      }
      this.currentTutorial--;
    },
  },
};
