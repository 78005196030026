import { getCategories } from "../../../api/DiverseyRooms.API";

export default {
  namespaced: true,
  state() {
    return {
      categories: null,
      currentCategory: null,
      currentSector: null,
      apiStatus: "idle",
      errorMessage: null,
    };
  },
  getters: {
    categories(state) {
      return state.categories;
    },
    currentCategory(state) {
      return state.currentCategory;
    },
    currentSector(state) {
      return state.currentSector;
    },
    apiStatus(state) {
      return state.apiStatus;
    },
  },
  mutations: {
    setCategories(state, { res, err, apiStatus }) {
      state.categories = res;
      state.errorMessage = err;
      state.apiStatus = apiStatus;
    },
    updateCurrentCategory(state, { category_slug }) {
      state.currentCategory = state.categories.find(
        (category) => category.slug === category_slug
      );
    },
    updateCurrentSector(state, { sector_slug }) {
      state.currentSector = state.currentCategory.sectors.find(
        (sector) => sector.slug === sector_slug
      );
    },
    setApiStatus(state, status) {
      state.apiStatus = status;
    },
  },
  actions: {
    async setCategories(context) {
      context.commit("setApiStatus", "busy");
      context.state.errorMessage = null;

      const timeout = new Promise((resolve, reject) => {
        setTimeout(() => {
          if (context.state.apiStatus === "busy") {
            reject(context.commit("setApiStatus", "failed"));
          } else {
            resolve();
          }
        }, 3000);
      });

      try {
        const res = await Promise.race([getCategories(), timeout]);
        context.commit("setCategories", {
          res,
          err: null,
          apiStatus: "idle",
        });
      } catch (err) {
        console.error(err);

        let errorMessage = "An error has occured";
        let apiStatus = "error";

        if (
          err.message &&
          (err.message.includes("CORS") ||
            err.message.includes("Request timed out"))
        ) {
          errorMessage =
            "A CORS error has occured or the request has timed out";
        }

        context.commit("setCategories", {
          res: null,
          err: errorMessage,
          apiStatus: apiStatus,
        });
      }
    },
    async updateCurrentCategory(context, { category_slug }) {
      try {
        await context.dispatch("setCategories");
        context.commit("updateCurrentCategory", { category_slug });
      } catch (err) {
        console.error(err);
      }
    },
    async updateCurrentSector(context, { category_slug, sector_slug }) {
      try {
        await context.dispatch("updateCurrentCategory", { category_slug });
        context.commit("updateCurrentSector", { category_slug, sector_slug });
      } catch (err) {
        console.error(err);
      }
    },
  },
};
