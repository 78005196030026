export async function setActiveLanguage(store) {
    if (!store.state.languages.languages.length) {
      await store.dispatch('languages/setLanguages');
    }
  }
  

  export function decodeHtml(html) {
    if (html == null) {
      return '';
    }
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
  }