<template>
  <div
    class="flex w-full flex-wrap items-center gap-2 md:h-full md:w-auto"
    :class="{
      'flex-wrap': solution_modal,
    }"
  >
    <button
      :aria-label="`Open modal for ${valueDriver.name}`"
      v-for="(valueDriver, index) in filteredDrivers"
      :key="index"
      class="duration-30 flex items-center transition-all ease-in-out hover:opacity-50"
      :class="{
        'rounded-full border border-[#0F0698] py-[3px] pl-2 pr-3':
          solution_modal,
      }"
      @click="openModal(valueDriver.id)"
    >
      <img
        class="h-8 w-8"
        :alt="valueDriver.name"
        :class="{
          'mr-0.5 h-[24px] w-[24px]': solution_modal,
        }"
        :src="valueDriver.image"
      />
      <span v-if="solution_modal" class="text-sm text-[#0F0698]">{{
        valueDriver.name
      }}</span>
    </button>
  </div>
</template>

<script lang="js" src="./ValueDriverButtons"></script>
