
export default {
  name: "InnovationSuperMarker",
  components: {
  },
  data() {
    return {};
  },
  props: ['title', 'thumbnail'],
  methods: {
    
  },
};


