<template>
  <button @click="openInnovations" type="button"
    class="innovation-btn text-semibold flex hidden items-center rounded-[28px] pl-4 pr-2.5 py-1.5 text-center text-sm text-black shadow-lg lg:flex justify-between"
    aria-label="Open innovations">
    <div class="flex items-center justify-between">
      <svg id="innovation-icon" width="13" height="15" viewBox="0 0 13 15" fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M3.99993 11V14H8.99993V11M3.99993 11H6.49993M3.99993 11C1.49993 8.66667 -1.60007 3.3 5.99993 0.5H7.5C10.5 1.66667 14.9999 5.4 8.99993 11M8.99993 11H6.49993M6.49993 11V7M6.49993 7L4.99993 5.5M6.49993 7L7.99993 5.5" />
        <path d="M4 12.5H9" />
        <rect x="5" y="14" width="3" height="1" />
      </svg>
      <span class="btn-label text-center capitalize pl-2 pr-5" v-html="getTranslations('innovation')">
      </span>
    </div>
    <label class="switch relative inline-block" for="innovation-toggle">
      <input id="innovation-toggle" class="w-full h-full border-none opacity-0 absolute top-0 left-0 cursor-pointer"
        type="checkbox" :checked="active" @change="toggleActive" aria-label="Toggle innovation">
      <span class="slider pointer-events-none"></span>
    </label>
  </button>
</template>

<script lang="js" src="./InnovationButton.js"></script>
<style lang="scss" src="./InnovationButton.scss" scoped></style>