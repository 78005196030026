import { mapGetters, mapActions } from "vuex";

import CategoryCard from "@/components/category-card/CategoryCard.vue";
import LanguageButton from "@/components/language-button/LanguageButton.vue";
import LanguageModal from "@/components/language-modal/LanguageModal.vue";

export default {
  name: "HomeScreen",
  components: {
    CategoryCard,
    LanguageButton,
    LanguageModal,
  },
  data: function () {
    return {
      show_language_modal: false,
    };
  },
  props: {
    msg: String,
  },
  mounted() {
    this.$store.dispatch("processes/setCategories");
    this.$store.dispatch("staticContent/setStaticContent");
  },
  computed: {
    ...mapGetters("processes", ["categories", "apiStatus", "errorMessage"]),
    ...mapGetters("languages", ["activeLanguage"]),
    ...mapGetters("staticContent", ["getTranslations"]),
  },
  methods: {
    ...mapActions("languages", ["setLanguages", "setActiveLanguage"]),
    toggleLanguageModal: function () {
      this.show_language_modal = !this.show_language_modal;
    },
    toTitleCase(str) {
      return str.replace(/\w\S*/g, (txt) => {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      });
    }
  }
};