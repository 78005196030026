<template>
  <div class="relative w-28 min-h-28 bg-[#ED8C1A] p-2 text-white text-center leading-4 rounded-md border border-white">
		<img :src="thumbnail != 'all' ? thumbnail : '/images/layout/placeholder.jpeg'" alt="solution image" class="mb-1.5 rounded-sm w-full h-[70px] object-cover"/>
		{{title}}
		<div class="absolute bottom-[-16px] left-1/2 transform -translate-x-1/2">
			<div class="w-0 h-0 border-solid border-8 border-transparent border-t-[#ED8C1A]"></div>
		</div>
  </div>
</template>

<script lang="js" src="./InnovationSuperMarker.js"></script>
<style lang="scss" src="./InnovationSuperMarker.scss" scoped></style>