import { getLanguages } from "../../../api/DiverseyRooms.API";

export default {
  namespaced: true,
  state() {
    return {
      languages: [],
      activeLanguage: null,
      apiStatus: "idle",
      errorMessage: null,
    };
  },
  getters: {
    languages(state) {
      return state.languages;
    },
    activeLanguage(state) {
      return state.activeLanguage;
    },
    apiStatus(state) {
      return state.apiStatus;
    },
  },
  mutations: {
    setLanguages(state, { res, apiStatus }) {
      state.languages = res;
      state.apiStatus = apiStatus;
    },

    setActiveLanguage(state, { activeLanguage }) {
      state.activeLanguage = activeLanguage;
    },
  },
  actions: {
    async setLanguages(context) {
      context.state.apiStatus = "busy";
      context.state.errorMessage = null;
      try {
        const res = await getLanguages();
        context.commit("setLanguages", {
          res,
          apiStatus: "idle",
        });

        const selectedLanguage = localStorage.getItem('activeLanguage');

        if (selectedLanguage) {
          context.dispatch("setActiveLanguage", {
            activeLanguage: JSON.parse(selectedLanguage),
          });
        }else{
          const defaultLanguage = res.find((l) => l.is_default === 1);
          context.dispatch("setActiveLanguage", {
            activeLanguage: defaultLanguage,
          });
        }

      } catch (err) {
        console.error("Error fetching languages: ", err);
        context.commit("setLanguages", {
          res: [],
          apiStatus: "error",
        });
      }
    },
    setActiveLanguage(context, { activeLanguage }) {
      context.commit("setActiveLanguage", { activeLanguage });
    },
  },
};
