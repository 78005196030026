import { mapGetters } from "vuex";

export default {
  name: "MoreButton",
  props: ["url"],
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
  },
  methods: {},
};
