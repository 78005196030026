export default {
    namespaced: true,
    state:{
        hotspots:[],
    },
    mutations: {
        setHotspots(state, payload) {
            state.hotspots = payload;
        },
    },
    getters: {
        hotspots(state) {
            return state.hotspots;
        },
    },
};