import axios from "axios";
import { getApiBase } from "./host";
import store from '../store/index.js';

// const auth_token = process.env.AUTH_TOKEN;
const auth_token = 'VKa3MPCKvDL302BYwy03ebLJcF5FRaMsSVJwHFftTLc5';
axios.defaults.headers.common['Authorization'] = 'Bearer ' + auth_token;

const getLanguageCode = () => {
  const active_language = store.getters['languages/activeLanguage'];
  return active_language ? active_language.two_digit_code : 'en';
};

const makeRequest = (url, resolve, reject) => {
  axios.get(url)
    .then(response => resolve(response.data))
    .catch(reject)
    .finally(() => {});
};

export const getLayers = () => {
  const url = `${getApiBase()}/v1/layer?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getCategory = slug => {
  const url = `${getApiBase()}/v1/content/individual-content/${slug}?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getCategories = () => {
  const url = `${getApiBase()}/v1/category?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getPortfolios = () => {
  const url = `${getApiBase()}/v1/portfolio?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getLanguages = () => {
  const url = `${getApiBase()}/v1/language`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};

export const getValueDrivers = () => {
  const url = `${getApiBase()}/v1/valuedriver?lang=${getLanguageCode()}`;
  return new Promise((resolve, reject) => makeRequest(url, resolve, reject));
};