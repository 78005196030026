export default {
  name: "BaseDialog",
  emits: ["close", "runAfterLeave"],
  props: {
    isOpen: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    isSolutionModal: {
      type: Boolean,
      default: false,
    },
  },
};
