<template>
	<div class="z-50 m-4 flex rounded-xl bg-white bg-opacity-70 text-sm shadow-lg backdrop-blur-sm backdrop-filter">
		<button @click="toggleLanguageModal" class="flex items-center gap-1.5 py-2 px-3">
			<img width="18" alt="Language Flag" :src="activeLanguage?.flag" />
			<h3 class="uppercase">
				{{ activeLanguage?.two_digit_code }}
			</h3>
		</button>
	</div>
</template>

<script lang="js" src="./LanguageButton.js"></script>
