import { mapGetters } from "vuex";

export default {
  name: "ValueDriverButtons",
  props: ["filterDriverIds", "solution_modal"],
  computed: {
    ...mapGetters("valueDrivers", ["valueDrivers"]),
    filteredDrivers() {
      if (!this.filterDriverIds) {
        return this.valueDrivers;
      }
      return this.valueDrivers.filter((driver) =>
        this.filterDriverIds.includes(driver.id)
      );
    },
  },
  data: () => ({
    show_modal: false,
  }),
  methods: {
    openModal: function (valueDriverId) {
      this.$emit("openModal", valueDriverId);
    },
  },
};
