<transition name="hotspot">
  <main>
    <section v-if="this.activeLayer.id !== 'solutions'">
      <div
        v-if="isHotspotVisible && show"
        @click="openModal"
        class="peg-component -translate-x-[50%] -translate-y-[200%] bg-white bg-opacity-90 py-2 px-2 shadow-lg"
        ref="peg"
        draggable="false"
        :class="{
				'-mt-[4%]': isGalleryFullScreen,
				}"
        :style="{left: (calculatedX() * 100 ) + '%', top: (calculatedY() * 100) + '%', transitionDelay: getAnimationDelay(), zIndex: (calculatedY() * 100)}"
      >
        <span class="text-center" v-html="title"></span>

        <div
          class="down-triangle absolute bottom-0 translate-y-full border-opacity-0"
        ></div>
      </div>
    </section>

    <section v-else>
      <main
        v-if="isHotspotVisible && show"
        @click="openModal"
        class="peg-component -translate-x-[50%] -translate-y-[200%] bg-white bg-transparent bg-opacity-90 shadow-lg"
        ref="peg"
        draggable="false"
        :class="{
				'-mt-[4%]': isGalleryFullScreen,
				}"
        :style="{left: (calculatedX() * 100) + '%', top: (calculatedY() * 100) + '%', transitionDelay: getAnimationDelay(), zIndex: (calculatedY() * 100)}"
      >
        <div
          @mouseover="hovered = true"
          @mouseleave="hovered = false"
          class="dot absolute bottom-0 translate-y-full border-opacity-0"
          :class="{
				'!bg-[#FE8A01]': portfolio == 7,
        '!bg-[#338dd5]': portfolio == 8,
				}"
        >
          <div
            v-if="getActivePortfolioName() == 'innovation'"
            class="absolute bottom-3 translate-x-[-46%]"
          >
            <InnovationSuperMarker :title="title" :thumbnail="thumbnail" />
          </div>
          <div
            v-if="getActivePortfolioName() == 'water saving'"
            class="absolute bottom-3 translate-x-[-46%]"
          >
            <WaterSavingSuperMarker :title="title" :percentage="percentage" />
          </div>
          <span
            v-if="hovered && getActivePortfolioName() != 'innovation' && getActivePortfolioName() != 'water saving'"
            v-html="title"
            :class="{
							'-top-[2.8rem]': hovered
						}"
            class="peg-title absolute left-1/2 w-max rounded-[.6em] bg-white bg-opacity-90 py-2 px-2 text-center text-[.75rem] shadow-lg"
          >
          </span>
          <div
            v-if="hovered && getActivePortfolioName() != 'innovation' && getActivePortfolioName() != 'water saving'"
            class="down-triangle-solution absolute bottom-0 h-[8px] w-[8px] translate-y-full border-opacity-0"
          ></div>
        </div>
      </main>
    </section>
  </main>
</transition>
