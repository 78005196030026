<template>
  <div
    class="z-50 m-4 flex rounded-xl bg-white bg-opacity-70 text-sm shadow-lg backdrop-blur-sm backdrop-filter"
  >
    <button @click="backToHome" class="border-r py-2 px-4 pr-2 capitalize">
      &lt; {{ getTranslations("back") }}
    </button>
    <button
      @click="openInfoModal"
      class="flex items-center gap-5 py-2 px-4 pl-4 capitalize md:pl-2"
    >
      <span class="hidden md:block">
        {{ getTranslations("help") }}
      </span>
      <img width="16" src="/images/icons/help-icon.svg" alt="help icon" />
    </button>
  </div>
</template>

<script lang="js" src="./BackInfoButtons.js"></script>
