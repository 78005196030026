<template>
  <div class="relative w-28 min-h-28 bg-[#4271C1] p-2 text-white text-center leading-4 rounded-md">
		<div class="w-full min-h-[55px] bg-[#DDE8F9] p-1 rounded-md mb-2 text-[#043282] text-center">
			<p class="text-center">Up to</p>
			<h3 class="text-2xl text-center">{{percentage}}%</h3>
		</div>
		{{title}}
		<div class="absolute bottom-[-16px] left-1/2 transform -translate-x-1/2">
			<div class="w-0 h-0 border-solid border-8 border-transparent border-t-[#4271C1]"></div>
		</div>
  </div>
</template>

<script lang="js" src="./WaterSavingSuperMarker.js"></script>
<style lang="scss" src="./WaterSavingSuperMarker.scss" scoped></style>