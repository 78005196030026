<template>
  <a :href="url" target="_blank" class="more_info">

    <p 
    v-html="getTranslations('more info')"
    class="text-left text-lg font-medium text-blue font-extrabold text-black transition-all duration-500 ease-in-out group-hover:text-white z-10 capitalize">
    </p>

    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 22 22" fill="none">
      <path d="M15.5388 11.5H6M11.4881 7L16.1922 11.5L11.4881 16L16.0615 11.5L11.4881 7Z" stroke="white"
        stroke-width="2.8" stroke-linecap="round" stroke-linejoin="round" />
    </svg>

  </a>
</template>


<script lang="js" src="./MoreButton.js"></script>
<style lang="scss" src="./MoreButton.scss" scoped></style>
