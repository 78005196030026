<teleport to="#app">
  <BaseDialog :isOpen="isVisible" @close="close">
    <template #default>
      <div class="relative flex w-[75vw] flex-col gap-4 md:w-[50vw] p-10">
        <button @click="close" class="absolute top-4 right-4 font-bold">
          <XMarkIcon
            class="h-[28px] w-[28px] text-[#a7a7a7] transition-all duration-300 hover:text-[#0f0698]"
          />
        </button>
        <section
          class="flex h-[50%] flex-col items-center justify-center gap-4"
        >
          <h1 class="text-center text-xl font-bold text-[#0f0f98] capitalize">
            {{ roomTitle }}
          </h1>
          <p class="text-[#333] text-center capitalize" v-html="getTranslations(tutorials[currentTutorial].title)"></p>
          <div class="relative my-14 w-[80%]">
            <img
              src="/images/tutorial/t-main.webp"
              alt="t-main"
              class="w-full"
            />
            <img
              src="/images/tutorial/t1.webp"
              alt="t1"
              class="absolute -top-10 -right-12 w-[30%] transition-all duration-300"
              :class="currentTutorial === 0 ? 'opacity-100' : 'opacity-0'"
            />
            <img
              src="/images/tutorial/t2.webp"
              alt="t2"
              class="absolute top-16 right-36  w-[40%] transition-all duration-300"
              :class="currentTutorial === 1 ? 'opacity-100' : 'opacity-0'"
            />
            <img
              src="/images/tutorial/t3.webp"
              alt="t3"
              class="absolute -top-10 -left-12 w-[50%] transition-all duration-300"
              :class="currentTutorial === 2 ? 'opacity-100' : 'opacity-0'"
            />
            <img
              src="/images/tutorial/t4.webp"
              alt="t4"
              class="absolute -top-10 -left-10 w-[60%] transition-all duration-300"
              :class="currentTutorial === 3 ? 'opacity-100' : 'opacity-0'"
            />
          </div>
          <div class="flex w-full items-center justify-center gap-1 py-1">
            <div
              v-for="(tutorial, index) in translatedTutorials"
              :key="tutorial"
              class="h-2 w-2 rounded-full bg-[#0f0698] transition-all duration-200"
              :class="tutorial.id === currentTutorial + 1 ? 'opacity-100' : 'opacity-50'"
            ></div>
          </div>
          <div class="flex flex-row  gap-4 md:w-1/2 ">
            <button
              @click="prevTutorial"
              class="cursor-pointer rounded-xl bg-[#e4e4eb] bg-opacity-80 p-2 align-bottom text-[#6f7274] transition-all duration-300 hover:bg-opacity-100 md:w-1/2 capitalize"
            >
              {{getTranslations('back')}}
            </button>
            <button
              @click="nextTutorial"
              class="cursor-pointer rounded-xl bg-[#0f0698] bg-opacity-80 p-2 align-bottom text-white transition-all duration-300 hover:bg-opacity-100 md:w-1/2 uppercase"
            >
              <div v-if="lastTutorial">{{getTranslations('finish')}}</div>
              <div v-else>{{getTranslations('next')}}</div>
            </button>
          </div>
        </section>
      </div>
    </template>
  </BaseDialog>
</teleport>
