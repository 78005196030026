import { mapGetters } from "vuex";
import { XMarkIcon } from "@heroicons/vue/24/outline";
import SquareArrowButton from "@/components/square-arrow-button/SquareArrowButton.vue";

export default {
  name: "ValueDriverModal",
  components: {
    XMarkIcon,
    SquareArrowButton,
  },
  props: ["isVisible", "valueDriverId", "runAfterLeave"],
  watch: {
    isVisible(newVal) {
      const event = new CustomEvent('modal-state-change', {
        detail: { isOpen: newVal }
      });
      document.dispatchEvent(event);
    }
  },
  computed: {
    ...mapGetters("valueDrivers", ["valueDrivers"]),
    ...mapGetters("staticContent", ["getTranslations"]),
    currentValueDriver() {
      return this.valueDrivers.find(
        (valueDriver) => valueDriver.id === this.valueDriverId
      );
    },
    title() {
      return this.currentValueDriver?.name;
    },
    description() {
      return this.currentValueDriver?.description;
    },
    iconSrc() {
      return this.currentValueDriver?.image;
    },
    baseURL() {
      return this.$router.resolve({
        name: "LayerScreen",
        params: {
          category: this.$route.params.category,
          sector: this.$route.params.sector,
          layer: "solutions",
        },
      }).href;
    },
    related_solutions() {
      const solutions = this.currentValueDriver?.related_solutions.map((solution) => ({
        title: solution.title,
        link: `${this.baseURL}/${solution.slug}`,
      })).sort((a, b) => a.title.localeCompare(b.title)) || [];
  
      return this.chunkArray(solutions, Math.ceil(solutions.length / 3));
    }
  },
  methods: {
    chunkArray(array, chunkSize) {
      const result = [];
      for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
      }
      return result;
    },
    close() {
      this.$emit("update:isVisible", false);
    },
  },
};
