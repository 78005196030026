<template>
  <teleport to="#app">
    <BaseDialog :isOpen="isVisible" @close="close" @runAfterLeave="runAfterLeave">
      <template #default>
        <div
          class="relative flex h-[90vh] w-[95vw] flex-col gap-4 overflow-scroll py-4 px-8 md:px-16 md:py-24 md:h-[80vh] md:w-[80vw]">
          <button @click="close" class="z-5 absolute right-4 md:right-12 md:top-10">
            <XMarkIcon class="h-[22px] w-[22px] text-[#a7a7a7] transition-all duration-300 hover:text-[#0f0698]" />
          </button>

          <div class="flex flex-col md:flex-row md:gap-20">
            <aside class="h-full pt-8 md:pt-0">
              <img :src="iconSrc" alt="" class="mx-auto w-[60%] h-auto md:m-0 md:w-full" />
            </aside>

            <aside class="flex flex-1 flex-col items-start justify-start gap-4">
              <h1 v-html="title" class="w-full text-left font-bold md:mb-8 md:text-4xl"></h1>
              <div class="value-driver leading-7" v-html="description"></div>

              <hr class="my-8 w-full border-[1px] border-[#dfe6ea]" />
              <section>
                <h4 class="mb-12 text-lg capitalize">
                  {{ getTranslations("related solutions") }}
                </h4>
                <div class="grid grid-cols-2 md:grid-cols-3 gap-4">
                  <div v-for="(chunk, col_index) in related_solutions" :key="col_index">
                    <div v-for="(related, index) in chunk" :key="index">
                      <RouterLink :to="related.link" @click="close" class="block mb-2">
                        <p v-html="related.title"></p>
                      </RouterLink>
                    </div>
                  </div>
                </div>
              </section>



            </aside>
          </div>

          <div class="valuedriver-aside"></div>
        </div>
      </template>
    </BaseDialog>
  </teleport>
</template>
<script src="./ValueDriverModal"></script>
<style scoped lang="scss" src="./Style.scss"></style>
