import { ChevronUpIcon } from "@heroicons/vue/24/outline";
import { mapGetters } from "vuex";

export default {
  name: "AccordionComponent",
  components: {
    ChevronUpIcon,
  },
  props: {
    title: String,
  },
  data() {
    return {
      isExpanded: true,
    };
  },
  methods: {
    toggleAccordion() {
      this.isExpanded = !this.isExpanded;
    },
  },
  computed: {
    ...mapGetters("staticContent", ["getTranslations"]),
  },
};
